<template>
  <div id="app">
    <Loading v-show="LOADING"></Loading>
    <router-view />
  </div>
</template>
<script setup>
import { useStore  } from "vuex";
import Loading from "@/components/loading/loading.vue";
import { computed } from "vue";
const store = useStore();
const LOADING = computed(() => store.state.LOADING);
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app {
  position: relative;
  // overflow-x: hidden;
}
/* 自定义滚动条 */
.custom-scrollbar {
  overflow: auto;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 12px; /* 滚动条宽度 */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* 滚动条轨道背景 */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888; /* 滚动条颜色 */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* 滚动条悬停颜色 */
}
</style>
