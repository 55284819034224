import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import Vue2Editor from "vue2-editor";
import "./assets/font/font.css";
import countTo from "vue3-count-to";
import vue3SeamlessScroll from "vue3-seamless-scroll";
import "normalize.css/normalize.css";
import "@/assets/styles/edit.css";
import * as utils from "@/utils/index";
import * as commonData from "@/utils/data";
import Vant from "vant";
import "vant/lib/index.css";
// import uView from 'uview-ui'
// 引入阿里云字体图标css
import "@/assets/iconfont/iconfont.css";
import "@/assets/iconfont/iconfont.js";
const app = createApp(App);
app.config.globalProperties.$utils = utils;
app.config.globalProperties.$commonData = commonData;

app.use(store);
app.use(ElementPlus);
app.use(router);
app.use(countTo);
app.use(Vue2Editor);
app.use(Vant);
app.use(vue3SeamlessScroll);
app.mount("#app");
