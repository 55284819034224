export const isArray = (e) => {
    return Object.prototype.toString.call(e).slice(8, -1) === 'Array'
  }
  export const isObject = (e) => {
    return Object.prototype.toString.call(e).slice(8, -1) === 'Object'
  }
  
  /**
   * This is just a simple version of deep copy
   * Has a lot of edge cases bug
   * If you want to use a perfect deep copy, use lodash's _.cloneDeep
   * @param {Object} oldData
   * @returns {Object}
   */
  export function deepClone(source) {
    let target = null
    if (!isArray(source) && !isObject(source)) {
      target = source
    }
    if (isArray(source)) {
      target = []
      for (let i = 0; i < source.length; i++) {
        target[i] = deepClone(source[i])
      }
    }
    if (isObject(source)) {
      target = {}
      for (let key in source) {
        target[key] = deepClone(source[key])
      }
    }
    return target
  }
  