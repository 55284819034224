export const menuList = [
  { name: "网站首页", path: "/home", key: "home", activePath: "/home" },
  { name: "关于我们", path: "/userJoin", key: "userJoin", activePath: "/userJoin" },
  {
    name: "教学内容", 
    key: "courses",
    children: [
      // 子菜单项列表
      {
        name: "职业技能鉴定",
        path: "/courses/vocational",
        key: "vocational",
      },
      {
        name: "中医技术",
        path: "/courses/medicine",
        key: "medicine",
      },
      {
        name: "政府班",
        path: "/courses/freeClasses",
        key: "freeClasses",
      },
    ],
  },
  {
    name: "师资团队", 
    key: "expertSpeaker",
    children: [
      // 子菜单项列表
      {
        name: "组织架构",
        path: "/expert/expertSpeaker",
        key: "expertSpeaker",
      },
      {
        name: "顾问团队",
        path: "/expert/advisoryTeam",
        key: "advisoryTeam",
      },
      {
        name: "客座教授",
        path: "/expert/professor",
        key: "professor",
      },
    ],
  },
  { name: "新闻列表", path: "/news", key: "news", activePath: "/news" },

];
