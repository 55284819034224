import { createStore } from "vuex";

export default createStore({
  state: {
    LOADING: false,
  },
  getters: {},
  mutations: {
    showLoading(state) {
      state.LOADING = true;
    },
    hideLoading(state) {
      state.LOADING = false;
    },
  },
  actions: {},
  modules: {},
});
