import { createRouter, createWebHashHistory } from "vue-router";
import { pcRouter } from "./pcRouter";
import { mobileRouter } from "./mobileRouter";
import { watch } from "vue";

let routes = [
  {
    path: "/",
    redirect: "/home",
  },
];

let screenWidth = document.body.clientWidth;
if (screenWidth <= 758) {
  routes = [...routes, ...mobileRouter];
  console.log(routes, "routes");
} else {
  routes = [...routes, ...pcRouter];
}

window.onresize = () => {
  screenWidth = document.body.clientWidth;
};

watch(
  screenWidth,
  (newVal, oldVal) => {
    let timer = setTimeout(() => {
      if (newVal <= 758) {
        routes = [...routes, ...mobileRouter];
        window.location.reload();
      } else {
        routes = [...routes, ...pcRouter];
        window.location.reload();
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  },
  { deep: true }
);
const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});
export default router;
