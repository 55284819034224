export const mobileRouter = [
  {
    path: "/home", // 首页
    name: "MobileIndex",
    component: () => import("../mobilePages/home/home.vue"),
    meta: { type: "mobile" },
  },
  {
    path: "/userJoin", // 关于我们
    name: "userJoin",
    component: () => import("../mobilePages/userJoin/userJoin.vue"),
    meta: { type: "mobile" },
  },
  {
    path: "/courses", // 父路由
    meta: { type: "mobile" },
    children: [
      {
        path: "vocational", // 子路由路径
        name: "MobileVocational",
        component: () => import("../mobilePages/courses/vocational.vue"),
        meta: { type: "mobile" },
      },
      {
        path: "medicine", // 子路由路径
        name: "MobileMedicine",
        component: () => import("../mobilePages/courses/medicine.vue"),
        meta: { type: "mobile" },
      },
      {
        path: "freeClasses", // 子路由路径
        name: "MobileFreeClasses",
        component: () => import("../mobilePages/courses/freeClasses.vue"),
        meta: { type: "mobile" },
      }
    ]
  },
  {
    path: "/expert", // 父路由
    meta: { type: "mobile" },
    children: [
      {
        path: "expertSpeaker", // 子路由路径
        name: "MobileExpertSpeaker",
        component: () => import("../mobilePages/expert/expertSpeaker.vue"),
        meta: { type: "mobile" },
      },
      {
        path: "advisoryTeam", // 子路由路径
        name: "MobileAdvisoryTeam",
        component: () => import("../mobilePages/expert/advisoryTeam.vue"),
        meta: { type: "mobile" },
      },
      {
        path: "professor", // 子路由路径
        name: "MobileProfessor",
        component: () => import("../mobilePages/expert/professor.vue"),
        meta: { type: "mobile" },
      }
    ]
  },
  {
    path: "/news", // 新闻列表
    name: "news",
    component: () => import("../mobilePages/news/news.vue"),
    meta: { type: "mobile" },
  },
  {
    path: "/newsInfo", // 新闻详情
    name: "newsInfo",
    component: () => import("../mobilePages/news/newsInfo.vue"),
    meta: { type: "mobile" },
  },
  {
    path: "/tableConfig", // table
    name: "tableConfig",
    component: () => import("../mobilePages/tableConfig/tableConfig.vue"),
    meta: { type: "mobile" },
  },
];
